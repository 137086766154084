<template>
  <div>
    <Panel
      :title="{
        title: $t('pages.summary.project_coach.title'),
        size: 'sm'
      }"
    >
      <div class="flex items-center flex-col lg:flex-row">
        <div class="sm:w-3/4 md:w-1/3 px-4 mb-2">
          <img
            class="rounded-lg mb-2"
            :src="require('@/assets/images/call.jpg')"
          />
        </div>
        <div class="w-full lg:w-2/3">
          <div class="ml-4 mb-4 text-left">
            <div class="mb-4 flex flex-col md:flex-row">
              <div class="mt-2">
                {{ $t("pages.summary.project_coach.paragraph_1") }}
              </div>
              <div>
                <img
                  class="w-24 md:w-40 m-auto"
                  :src="require('@/assets/images/tel-verif.png')"
                />
              </div>
            </div>
            <div class="cmn-text-gray-dark text-center mb-4">
              <b>{{ $t("pages.summary.project_coach.paragraph_2") }}</b>
            </div>
            <div>
              {{ $t("pages.summary.project_coach.paragraph_3") }}
            </div>
            <div class="mb-2">
              {{ $t("pages.summary.project_coach.paragraph_4") }}
            </div>
          </div>
            <Button shape="pills" @click="initModal">{{
              $t("pages.summary.project_coach.modal_show")
            }}</Button>
        </div>
      </div>
    </Panel>
    <Modal
      v-show="showModal"
      type="success"
      :title="$t('pages.summary.project_coach.modal.title')"
      :actions="{
        cancelVisible: true,
        cancelLabel: 'Annuler',
        actionVisible: true,
        actionLabel: $t('pages.summary.project_coach.modal.action'),
        backdropClickEnabled: false
      }"
      @action="callMeBack()"
      @cancel="showModal = false"
    >
      <div class="md:flex md:w-3/4 mx-auto mt-4">
        <div class="md:w-1/3 mt-2 text-left text-xl">
          Nom :
        </div> 
        <div class="bg-gray-light rounded-xxl md:w-2/3 mt-2 px-2">
          <input
          type="text"
            v-model="lastname"
            :placeholder="lastname"
            class="self-center bg-transparent w-full outline-none px-2 text-xl"
          />
        </div>
      </div>
      <div class="md:flex md:w-3/4 mx-auto mt-2">
        <div class="md:w-1/3 mt-2 text-left text-xl">
          Prénom :
        </div> 
        <div class="bg-gray-light rounded-xxl md:w-2/3 mt-2 px-2">
          <input
          type="text"
            v-model="firstname"
            :placeholder="firstname"
            class="self-center bg-transparent w-full outline-none px-2 text-xl"
          />
        </div>
      </div>
      <div class="md:flex md:w-3/4 mx-auto mt-2">
        <div class="md:w-1/3 mt-2 text-left text-xl">
          Tel :
        </div> 
        <div class="bg-gray-light rounded-xxl md:w-2/3 mt-2 px-2">
          <input
          type="text"
            v-model="tel"
            :placeholder="tel"
            @keypress="onlyNumber"
            class="self-center bg-transparent w-full outline-none px-2 text-xl"
          />
        </div>
      </div>
      <div class="mt-8">
        {{ $t('pages.summary.project_coach.modal.paragraph') }}
      </div>
  </Modal>
  <Modal
      v-show="showModalConfirm"
      type="success"
      :title="$t('pages.summary.project_coach.modal_confirm.title')"
      :actions="{
        cancelVisible: false,
        cancelLabel: 'Annuler',
        actionVisible: true,
        actionLabel: $t('pages.summary.project_coach.modal_confirm.action'),
        backdropClickEnabled: false
      }"
      @action="showModalConfirm = false"
    >
      <div class="md:flex w-3/4 mx-auto mt-6">
        <div class="hidden md:block w-1/4 mt-2">
          <Tick class="w-16 h-16"/>
        </div> 
        <div class="md:w-3/4 text-xl self-center">
          <div>{{ $t('pages.summary.project_coach.modal_confirm.paragraph_1') }}</div>
          <div>{{ $t('pages.summary.project_coach.modal_confirm.paragraph_2') }}</div>
        </div>
      </div>
      <div class="mt-8 text-center">
        {{ $t('pages.summary.project_coach.modal_confirm.paragraph_3') }}
      </div>
  </Modal>
  </div>
</template>

<script>
import Tick from "@/assets/images/tick.svg?inline";
import { mapState, mapGetters } from "vuex";

export default {
  name: "ProjectCoach",

  components: {
        Tick
    },

  data() {
    return {
      showModal: false,
      showModalConfirm: false,
      firstname: "",
      lastname: "",
      tel: ""
    };
  },

  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["gtmProfile"]),
  },

  methods: {
    initModal() {
      this.lastname = this.user.last_name;
      this.firstname = this.user.first_name;
      this.tel = this.user.phone;
      this.showModal = true;
    },

    onlyNumber(event) {
      if (!/\d/.test(event.key)) return event.preventDefault();
    },

    async callMeBack() {
      if (this.lastname != '' && this.firstname != '' && this.tel != '') {
        this.showModal = false;
        this.user.lastname = this.lastname;
        this.user.firstname = this.firstname;
        this.user.phone = this.tel;
        this.$store.dispatch("user/hubspotCallBack", {
          hubspot_event: "qualification",
          last_name: this.lastname,
          first_name: this.firstname,
          tel: this.tel
        });
        this.showModalConfirm = true;
      }
    }
  }
};
</script>
