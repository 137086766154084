<template>
  <div>
    <!-- mobile -->
    <div class="md:hidden">
      <article>
        <div
          class="flex flex-col justify-around p-6 bg-gray-cloud bg-opacity-50 rounded-xl"
        >
          <div>
            {{ $t("pages.summary.new_search.paragraph_1") }}
          </div>
          <div class="mt-4">
            <Button
              appearance="outlined"
              shape="pills"
              @click="cmnSearchUrl()"
              >{{ $t("pages.summary.new_search.new_search") }}</Button
            >
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ProjectNewSearch",

  methods: {
    ...mapActions("user", ["fetchMe"]),

    async cmnSearchUrl() {
      await this.fetchMe();
      window.location.replace(process.env.VUE_APP_CMN_URL + "/recherche");
    }
  }
};
</script>
