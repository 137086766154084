<template>
  <div>
    <!-- mobile -->
    <div class="md:hidden">
      <article v-if="hasSearches" class="mb-10">
        <header class="flex justify-between mb-2">
          <h2 class="font-lato font-bold text-primary-40 text-xl">
            {{ $t("pages.summary.your_latest_interactions_mobile") }}
          </h2>
        </header>
        <div
          v-if="interactionsForList.length > 0"
          class="flex flex-col items-center p-6 bg-gray-cloud bg-opacity-50 rounded-xl"
        >
          <ProjectInteractionList
            :interactions="interactionsForList"
            :professionals="professionals"
          ></ProjectInteractionList>
          <div class="mt-4">
            <router-link
              :to="{
                name: 'ProjectChats'
              }"
              v-slot="{ href, navigate }"
            >
              <Button
                appearance="outlined"
                shape="pills"
                @click="navigate"
                :href="href"
                >{{ $t("pages.summary.interaction_open_details") }}</Button
              >
            </router-link>
          </div>
        </div>
        <div
          v-if="interactionsForList.length == 0"
          class="flex flex-col items-center p-6 bg-gray-cloud bg-opacity-50 rounded-xl"
        >
          <div class="text-sm">
            <div class="mb-2 font-bold">
              {{ $t("pages.summary.no_interactions") }}
            </div>
          </div>
          <div class="p-3"><ImageSearchSent /></div>
        </div>
      </article>
      <article>
        <header class="flex justify-between mb-2">
          <h2 class="font-lato font-bold text-primary-40 text-xl">
            {{ $t("pages.summary.your_statistics") }}
          </h2>
        </header>
        <div
          class="flex justify-around items-start p-6 bg-gray-cloud bg-opacity-50 rounded-xl"
        >
          <ProjectBarChart
            type="mail"
            :value="interactionCount.email"
            :total="totalInteractions"
          />
          <ProjectBarChart
            :value="interactionCount.phone"
            :total="totalInteractions"
          />
          <ProjectBarChart
            type="proposal"
            :value="interactionCount.chat"
            :total="totalInteractions"
          />
        </div>
      </article>
    </div>
    <!-- desktop -->
    <div class="hidden md:block">
      <Panel
        :title="{
          title: $t('pages.summary.project_related_interactions'),
          size: 'sm'
        }"
      >
        <div class="flex flex-row items-center">
          <div class="flex-1 flex-grow-1">
            <div class="flex flex-row justify-around items-center mr-4">
              <ProjectCircleChart
                type="mail"
                :value="interactionCount.email"
                :total="totalInteractions"
              />
              <ProjectCircleChart
                :value="interactionCount.phone"
                :total="totalInteractions"
              />
              <ProjectCircleChart
                type="proposal"
                :value="interactionCount.chat"
                :total="totalInteractions"
              />
            </div>
            <div v-if="interactionsForList.length > 0" class="mt-4">
              <router-link
                :to="{
                  name: 'ProjectInteractions',
                  params: { chatId: interactionsForList[0].chat_id }
                }"
              >
                <Button size="sm" shape="pills">{{
                  $t("pages.summary.interaction_open_details")
                }}</Button>
              </router-link>
            </div>
          </div>
          <div v-if="!hasSearches" class="flex items-center flex-col">
            <div class="text-sm">
              <div class="mb-2 font-bold">
                {{ $t("pages.summary.no_searches") }}
              </div>
            </div>
            <div class="p-3"><ImageSearchSent /></div>
          </div>
          <div
            v-if="hasSearches && interactionsForList.length == 0"
            class="flex items-center flex-col"
          >
            <div class="text-sm">
              <div class="mb-2 font-bold">
                {{ $t("pages.summary.no_interactions") }}
              </div>
            </div>
            <div class="p-3"><ImageSearchSent /></div>
          </div>
          <div
            v-if="hasSearches && interactionsForList.length > 0"
            class="flex-1 flex-grow-1 interactions-last"
          >
            <ProjectInteractionList
              :title="$t('pages.summary.your_latest_interactions')"
              :interactions="interactionsForList"
              :professionals="professionals"
            ></ProjectInteractionList>
          </div>
        </div>
      </Panel>
    </div>
  </div>
</template>

<script>
import ProjectBarChart from "@/components/project/ProjectBarChart.vue";
import ProjectCircleChart from "@/components/project/ProjectCircleChart.vue";
import ProjectInteractionList from "@/components/project/ProjectInteractionList.vue";
import ImageSearchSent from "@/assets/images/search-sent.svg?inline";

export default {
  name: "ProjectInteractions",

  components: {
    ProjectBarChart,
    ProjectCircleChart,
    ProjectInteractionList,
    ImageSearchSent
  },

  props: {
    hasSearches: {
      type: Boolean,
      default: false
    },

    interactionCount: {
      type: Object
    },

    interactions: {
      type: Array
    },

    professionals: {
      type: Array
    }
  },

  computed: {
    interactionsForList() {
      return this.interactions.slice(0, 3);
    },

    totalInteractions() {
      return (
        this.interactionCount.phone +
        this.interactionCount.email +
        this.interactionCount.chat
      );
    }
  }
};
</script>

<style class="postcss" scoped>
.interactions-last {
  max-width: calc(50% - (theme("spacing.4") / 2));
}
</style>
