<template>
  <div
    id="gamification-panel"
    class="flex flex-row justify-between mb-2 relative margin-percent"
  >
    <GameAvatar
      :step="0"
      :label="labelArray[0]"
      :highlighted="highlightedArray[0]"
      :purchaseMode="purchaseMode"
      @step-change="stepChange"
      class="z-1"
    />
    <GameAvatar
      :step="1"
      :label="labelArray[1]"
      :highlighted="highlightedArray[1]"
      :purchaseMode="purchaseMode"
      @step-change="stepChange"
      class="z-1"
    />
    <GameAvatar
      :step="2"
      :label="labelArray[2]"
      :highlighted="highlightedArray[2]"
      :purchaseMode="purchaseMode"
      @step-change="stepChange"
      class="z-1"
    />
    <div class="line"></div>
    <div
      class="line highlighted"
      :class="{
        'animated-half': highlightedArray[1],
        'animated-full': highlightedArray[2]
      }"
    ></div>
    <Modal
      v-show="showModal"
      @action="onModalValidate"
      @cancel="onModalCancel"
      :title="
        purchaseMode
          ? $t('pages.summary.gamification.which_pro_purchase')
          : $t('pages.summary.gamification.which_pro_sale')
      "
      :actions="{
        actionVisible: true,
        actionLabel: 'Valider',
        cancelVisible: true,
        cancelLabel: 'Annuler',
        backdropClickEnabled: false,
        actionDisabled:
          selectedPros.length == 0 && !noPro && selectedPro == null
      }"
    >
      <div class="pro-select overflow-y-scroll">
        <ul class="ml-5 mt-5">
          <li
            v-for="pro in proArray"
            :key="pro.id"
            class="flex flex-row justify-start items-center cursor-pointer pb-3"
          >
            <RadioButton
              v-if="purchaseMode"
              v-model="selectedPro"
              :value="pro.id"
            />
            <Checkbox
              v-else
              v-model="selectedPros"
              :value="pro.id"
              @change="onProCheckChange"
            />
            <div class="flex flex-row" @click="onAvatarClick(pro)">
              <Avatar :name="pro.company" :imageUrl="pro.avatar_url" />
              <div class="flex-1 ml-3">
                <div
                  class=" flex flex-col text-left justify-between items-start font-sans"
                >
                  <div class="text-sm font-bold text-gray-smoke">
                    {{ pro.company }}
                  </div>
                  <div class="text-sm text-gray-smoke break-all">
                    {{ pro.email }}
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="text-left cursor-pointer">
            <RadioButton v-if="purchaseMode" v-model="selectedPro" :value="0" />
            <Checkbox v-else v-model="noPro" @change="onNoneCheckChange" />
            <span @click="onNoneClick()">{{
              $t("pages.summary.gamification.none_of_them")
            }}</span>
          </li>
        </ul>
      </div>
    </Modal>
  </div>
</template>

<script>
import GameAvatar from "@/components/Gamification/GameAvatar.vue";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "Gamification",
  components: {
    GameAvatar
  },

  data() {
    return {
      showModal: false,
      selectedPros: [],
      selectedPro: null,
      noPro: false,
      chosenStep: 0
    };
  },

  props: {
    labelArray: {
      type: Array,
      default: () => {
        return [];
      }
    },
    purchaseMode: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapState("progress", ["purchase", "sale"]),
    ...mapState("pro", ["proArray"]),
    ...mapGetters("user", ["gtmProfile"]),

    progressObject() {
      return this.purchaseMode ? this.purchase : this.sale;
    },

    disabledProCheckboxes() {
      return this.selectedPros["none"];
    },

    highlightedArray() {
      let res = [
        this.progressObject.step >= 0,
        this.progressObject.step >= 1,
        this.progressObject.step >= 2
      ];
      return res;
    },
    proIds() {
      if (this.purchaseMode) {
        return this.selectedPro == 0 || this.selectedPro == null
          ? null
          : [this.selectedPro];
      } else
        return this.selectedPros.length == 0 ? null : [...this.selectedPros];
    }
  },

  methods: {
    ...mapActions("progress", {
      updateStep: "updateStep"
    }),

    stepChange(step) {
      this.chosenStep = step;
      if (
        (this.purchaseMode && step == 2) ||
        (!this.purchaseMode &&
          (step == 1 ||
            (step == 2 &&
              this.noPro == false &&
              this.selectedPros.length == 0)))
      ) {
        this.showModal = true;
      } else {
        this.updateStep({
          purchaseMode: this.purchaseMode,
          payload: {
            ...this.progressObject,
            step: step,
            pro_ids: this.proIds
          }
        });
        this.goToGiftCard();
      }
    },
    onModalValidate() {
      this.updateStep({
        purchaseMode: this.purchaseMode,
        payload: {
          ...this.progressObject,
          step: this.chosenStep,
          pro_ids: this.proIds
        }
      });
      this.goToGiftCard();
      const action = `Step-${this.chosenStep + 1}${
        this.purchaseMode ? "A" : "V"
      }`;
      this.$gtm.trackEvent({
        event: "user-project-step",
        eventCategory: "User Project Step",
        eventAction: action,
        eventLabel: this.gtmProfile,
        eventValue: "",
        noninteraction: false
      });
      this.showModal = false;
    },

    onModalCancel() {
      this.showModal = false;
    },

    onNoneCheckChange(checked) {
      if (checked) {
        this.selectedPros = [];
      }
    },

    onProCheckChange(checked) {
      if (checked && this.noPro) {
        this.noPro = false;
      }
    },

    onAvatarClick(pro) {
      if (this.purchaseMode) {
        this.selectedPro = pro.id;
      } else {
        let proNotYetSelected = true;

        if (this.selectedPros.includes(pro.id)) {
          proNotYetSelected = false;
          this.selectedPros.splice(this.selectedPros.indexOf(pro.id), 1);
        } else this.selectedPros.push(pro.id);

        this.onProCheckChange(proNotYetSelected);
      }
    },

    onNoneClick() {
      if (this.purchaseMode) {
        this.selectedPro = 0;
      } else {
        this.noPro = !this.noPro;
        this.onNoneCheckChange(this.noPro);
      }
    },

    goToGiftCard() {
      if (!this.purchaseMode) {
        const giftCardPanel = document.getElementById("gift-card-panel");
        if (giftCardPanel) {
          window.scroll({
            top: giftCardPanel.offsetTop - 100,
            left: 0,
            behavior: "smooth"
          });
        }
      }
    }
  },

  mounted() {
    if (this.purchaseMode) {
      this.selectedPro =
        this.purchase.pro_ids && this.sale.pro_ids.length > 0
          ? this.sale.pro_ids[0]
          : null;
    } else {
      if (this.sale.pro_ids && this.sale.pro_ids.length > 0) {
        this.selectedPros = [...this.sale.pro_ids];
      } else {
        this.selectedPros = [];
      }
    }
  }
};
</script>

<style scoped>
.line {
  position: absolute;
  border-bottom: 1px solid #e8e8e8;
  width: 100%;
  top: 39px;
}

.highlighted {
  border-bottom: 3px solid #cacccf;
  width: 0;
  top: 40px;
  transition: width 0.3s ease;
}

.animated-half {
  width: 50%;
}

.animated-full {
  width: 100%;
}
.margin-percent {
  margin-left: 7%;
  margin-right: 7%;
}

.pro-select {
  max-height: 250px;
}
</style>
