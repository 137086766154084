<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size * 2"
    :height="size * 2 + textMargin + 15"
    :viewBox="`0 0 ${size * 2} ${size * 2 + textMargin}`"
  >
    <defs>
      <linearGradient
        :id="`chartGradient-circle-${this.type}`"
        x1="0%"
        y1="0%"
        x2="0%"
        y2="100%"
      >
        <stop offset="0%" :stop-color="gradientColors.stop" />
        <stop offset="100%" :stop-color="gradientColors.start" />
      </linearGradient>
    </defs>
    <g>
      <circle
        :cx="size"
        :cy="size"
        :r="radius"
        :stroke-width="strokeWidth"
        fill="none"
        class="text-gray-cloud stroke-current"
      />
      <circle
        :cx="size"
        :cy="size"
        :r="radius"
        :stroke="`url(#chartGradient-circle-${this.type})`"
        :stroke-width="strokeWidth"
        stroke-linecap="round"
        :stroke-dashoffset="dashoffset"
        :stroke-dasharray="dasharray"
        fill="none"
        class="origin-center transform -rotate-90"
        style="transform-box: fill-box;"
      />
      <text
        :x="size"
        :y="size + 2"
        text-anchor="middle"
        alignment-baseline="middle"
        :fill="gradientColors.start"
        class="font-lato font-black text-xl"
      >
        {{ value }}
      </text>
      <text
        :x="size"
        :y="size * 2 + textMargin"
        text-anchor="middle"
        alignment-baseline="middle"
        class="font-sans text-xs text-gray-smoke origin-center transform -translate-y-1/2"
        style="transform-box: fill-box;"
      >
        {{ legend }}
      </text>
    </g>
  </svg>
</template>

<script>
import ProjectChart from "@/components/project/ProjectChartMixin";

export default {
  name: "ProjectCircleChart",

  mixins: [ProjectChart],

  data() {
    return {
      size: 50,
      strokeWidth: 8,
      textMargin: 25
    };
  },

  computed: {
    radius() {
      return this.size - this.strokeWidth;
    },

    dasharray() {
      return Math.round(2 * 3.1415927 * this.radius);
    },

    dashoffset() {
      return this.dasharray - this.dasharray * this.percent;
    }
  }
};
</script>
