<template>
    <div>
        <!-- mobile -->
        <div class="md:hidden">
            <article>
                <header class="flex justify-between mb-2">
                    <h2 class="font-lato font-bold text-primary-40 text-xl">
                        {{ $t("pages.summary.project_related_ads_mobile") }}
                    </h2>
                </header>
                <div class="flex w-full justify-around items-start p-6 bg-opacity-50 rounded-xl">
                    <Loading v-if="isPolling">Recherche d'annonces correspondant à votre projet</Loading>
                    <div v-else class="flex flex-1 flex-col gap-2">
                        <AdMini class="w-4/5 mx-auto" v-for="ad in summarySortedAds" :key="ad.id" :ad="ad" />
                        <div class="flex gap-1">
                            <Button class="mt-6" @click="$router.push({ name: 'AllAds' })">{{ $t('pages.ads.view_all') +
                                '(' + sortedAds.length + ')' }}</Button>
                            <Button @click="$router.push({ name: 'LikedAds' })">
                                <Like class="w-6 h-6 mr-1">
                                </Like>{{ likedAds.length }}
                            </Button>
                            <Button @click="$router.push({ name: 'ContactedAds' })">
                                <Phone class="w-6 h-6  mr-1">
                                </Phone>{{ contactedAds.length }}
                            </Button>
                        </div>
                    </div>
                </div>
            </article>
        </div>
        <!-- desktop -->
        <div class="hidden md:block">
            <Panel :title="{ title: $t('pages.summary.project_related_ads'), size: 'lg' }">
                <Loading v-if="isPolling">Recherche en cours</Loading>
                <div v-else class="flex flex-col gap-10 items-center">
                    <div class="flex w-full px-6 flex-wrap gap-2 justify-around">
                        <AdMini v-for="ad in summarySortedAds" :key="ad.id" :ad="ad" />
                    </div>
                    <div class="flex gap-1">
                        <Button @click="$router.push({ name: 'AllAds' })">{{ $t('pages.ads.view_all') + ' (' +
                            sortedAds.length +
                            ')'
                        }}</Button>
                        <Button color="secondary" @click="$router.push({ name: 'LikedAds' })">
                            <Like class="w-6 h-6 mr-1">
                            </Like>{{ likedAds.length }}
                        </Button>
                        <Button color="secondary" @click="$router.push({ name: 'ContactedAds' })">
                            <Phone class="w-6 h-6  mr-1">
                            </Phone>{{ contactedAds.length }}
                        </Button>
                    </div>
                </div>
            </Panel>
        </div>
    </div>
</template>

<script>
import AdMini from "@/components/Ad/AdMini.vue";
import { mapGetters } from "vuex";
import Loading from "../Loading.vue";
import Phone from "@/assets/images/phone.svg?inline";
import Like from "@/assets/images/like.svg?inline";

export default {
    name: 'ProjectAds',
    components: {
        AdMini,
        Loading,
        Phone,
        Like,
    },
    computed: {
        ...mapGetters('ad', ['isPolling', 'sortedAds', 'summarySortedAds', 'likedAds', 'contactedAds']),
    }
}

</script>
