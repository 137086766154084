<template>
  <div class="relative">
    <Component
      class="h-20 w-20 rounded-full p-2 hover:border-gray-wall bg-white cursor-pointer"
      :class="{
        deactivated: !highlighted,
        border: !highlighted,
        'border-gray-cloud': !highlighted,
        'border-gray-wall': highlighted,
        'border-3': highlighted
      }"
      :is="stageComponent"
      @click="changeStep"
    />
    <Star class="h-4 w-4 star star-1" :class="{ animate: selected }" />
    <Star class="h-4 w-4 star star-2" :class="{ animate: selected }" />
    <Star class="h-4 w-4 star star-3" :class="{ animate: selected }" />
    <Star class="h-4 w-4 star star-4" :class="{ animate: selected }" />
    <Star class="h-4 w-4 star star-5" :class="{ animate: selected }" />
    <Star class="h-4 w-4 star star-6" :class="{ animate: selected }" />
    <div class="w-17 font-sans text-xs text-gray-smoke">{{ label }}</div>
  </div>
</template>

<script>
import StagePurchase1 from "@/assets/images/step1.svg?inline";
import StagePurchase2 from "@/assets/images/step2A.svg?inline";
import StagePurchase3 from "@/assets/images/step3A.svg?inline";
import StageSale1 from "@/assets/images/step1.svg?inline";
import StageSale2 from "@/assets/images/step2V.svg?inline";
import StageSale3 from "@/assets/images/step3V.svg?inline";
import Star from "@/assets/images/spark.svg?inline";

export default {
  name: "GameAvatar",

  components: {
    StagePurchase1,
    StagePurchase2,
    StagePurchase3,
    StageSale1,
    StageSale2,
    StageSale3,
    Star
  },

  props: {
    step: {
      type: Number,
      default: 0
    },
    highlighted: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    purchaseMode: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      selected: false
    };
  },

  computed: {
    stageComponent() {
      return (
        "Stage" + (this.purchaseMode ? "Purchase" : "Sale") + (this.step + 1)
      );
    }
  },

  methods: {
    changeStep() {
      if (!this.highlighted) {
        this.selected = true;
        setTimeout(() => {
          this.selected = false;
        }, 1200);
      }
      setTimeout(() => {
        this.$emit("step-change", this.step);
      }, 400);
    }
  }
};
</script>

<style>
span {
  top: 30%;
}

.deactivated {
  filter: grayscale(1) blur(0.8px);
}

.deactivated:hover {
  filter: grayscale(0.3) blur(0.8px);
  transition: filter 0.2s;
}

.star {
  visibility: hidden;
  position: absolute;
  top: 0px;
  left: 31px;
  transition: transform 0.6s ease, opacity 1.2s;
}

.animate {
  visibility: visible;
  opacity: 0;
}

.star-4,
.star-5,
.star-6 {
  top: 60px;
}

.star-1.animate {
  transform: translateY(-50px) rotate(360deg) scale(2);
}

.star-2.animate {
  transform: translateY(-50px) translateX(-40px) rotate(360deg) scale(2);
}

.star-3.animate {
  transform: translateY(-50px) translateX(40px) rotate(360deg) scale(2);
}

.star-4.animate {
  transform: translateY(50px) rotate(360deg) scale(2);
}

.star-5.animate {
  transform: translateY(50px) translateX(-40px) rotate(360deg) scale(2);
}

.star-6.animate {
  transform: translateY(50px) translateX(40px) rotate(360deg) scale(2);
}
</style>
