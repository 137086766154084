var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Panel',{attrs:{"title":{
    title: _vm.$t('pages.summary.signed_documents.title'),
    size: 'sm'
  }}},[_c('ul',_vm._l((_vm.documents),function(document){return _c('li',{key:document.id,staticClass:"border-b border-gray-cloud last:border-b-0 hover:bg-gray-cloud hover:bg-opacity-50"},[_c('a',{staticClass:"flex flex-row items-center h-12 cursor-pointer",attrs:{"href":document.signing_details.signers[0].signature_link,"target":"_blank"}},[_c('div',{staticClass:"flex flex-grow flex-row text-left truncate sm:pl-2"},[_vm._v(" Mandat de recherche N° "+_vm._s(document.reference)+" ")])])])}),0),_c('Modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.showModalConfirm),expression:"showModalConfirm"}],attrs:{"type":"success","title":_vm.$t('pages.summary.signed_documents.modal_confirm.title'),"actions":{
      cancelVisible: false,
      cancelLabel: 'Annuler',
      actionVisible: true,
      actionLabel: _vm.$t('pages.summary.signed_documents.modal_confirm.action'),
      backdropClickEnabled: false
    }},on:{"action":function($event){_vm.showModalConfirm = false}}},[_c('div',{staticClass:"md:flex w-3/4 mx-auto mt-6"},[_c('div',{staticClass:"hidden md:block w-1/4 mt-2"},[_c('Tick',{staticClass:"w-16 h-16"})],1),_c('div',{staticClass:"md:w-3/4 text-xl self-center"},[_c('div',[_vm._v(_vm._s(_vm.$t('pages.summary.signed_documents.modal_confirm.paragraph_1')))])])])]),_c('Snackbar',_vm._b({model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},'Snackbar',_vm.snackbarProps,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }