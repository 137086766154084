<template>
  <div class="w-full">
    <header v-if="title" class="pb-2 border-b-2 border-gray-cloud">
      <h2 class="text-left leading-5 text-gray-smoke">
        {{ title }}
      </h2>
    </header>
    <ul>
      <li
        v-for="interaction in interactions"
        :key="interaction.id"
        class="border-b border-gray-cloud last:border-b-0 hover:bg-gray-cloud hover:bg-opacity-50"
      >
        <router-link
          :to="{
            name: 'ProjectInteractions',
            params: { chatId: interaction.chat_id }
          }"
          class="flex flex-row items-center h-12"
        >
          <span class="flex items-center flex-1 min-w-0 pl-2">
            <InteractionBadge
              :type="interaction.interaction_type"
              class="flex-shrink-0"
            />
            <span
              class="p-2 leading-5 truncate text-gray-smoke"
              v-html="getProfessionalLabel(interaction.pro_id)"
            />
          </span>
          <Icon
            name="chevron"
            size="md"
            class="m-1 pr-2 text-gray-elephant transform -rotate-90 lg:hidden"
          />
          <span class="hidden text-primary-40 pr-2 lg:block">{{
            $t("pages.summary.interaction_list_open")
          }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "ProjectInteractionList",

  props: {
    title: {
      type: String
    },

    interactions: {
      type: Array,
      default: () => []
    },

    professionals: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    getProfessionalLabel(professionalId) {
      let professional = _.find(this.professionals, { id: professionalId });
      return !_.isNil(professional)
        ? professional.company +
            (!_.isNil(professional.city)
              ? '<span class="text-gray-elephant"> - </span>' +
                professional.city
              : "")
        : "";
    }
  }
};
</script>
