<template>
  <div class="mb-10">
    <Panel :title="{
      title: $t('pages.summary.unsigned_document.title') + ' ' + document.reference,
      size: 'sm'
    }">
      <div class="flex items-center flex-col lg:flex-row">
        <div class="w-full">
          <div class="mb-4">
            {{ $t("pages.summary.unsigned_document.paragraph_1") }}
          </div>
          <Button shape="pills" @click="initModal">{{
            $t("pages.summary.unsigned_document.modal_show")
          }}</Button>
        </div>
      </div>
    </Panel>
    <Modal v-show="showModal" type="info"
      :title="$t('pages.summary.unsigned_document.modal.title') + ' ' + document.reference" :actions="{
        cancelVisible: true,
        cancelLabel: 'Annuler',
        actionVisible: true,
        actionLabel: $t('pages.summary.unsigned_document.modal.action'),
        backdropClickEnabled: false
      }" @action="sign()" @cancel="showModal = false">
      <div class="md:flex md:w-3/4 mx-auto mt-4">
        <div class="md:w-1/3 mt-2 text-left md:text-xl">
          Nom :
        </div>
        <div class="bg-gray-light rounded-xxl md:w-2/3 mt-2 px-2">
          <input type="text" v-model="lastname" :placeholder="lastname"
            class="self-center bg-transparent w-full outline-none px-2 md:text-xl" />
        </div>
      </div>
      <div class="md:flex md:w-3/4 mx-auto mt-2">
        <div class="md:w-1/3 mt-2 text-left md:text-xl">
          Prénom :
        </div>
        <div class="bg-gray-light rounded-xxl md:w-2/3 mt-2 px-2">
          <input type="text" v-model="firstname" :placeholder="firstname"
            class="self-center bg-transparent w-full outline-none px-2 md:text-xl" />
        </div>
      </div>
      <div class="md:flex md:w-3/4 mx-auto mt-2">
        <div class="md:w-1/3 mt-2 text-left md:text-xl">
          Tel :
        </div>
        <div class="bg-gray-light rounded-xxl md:w-2/3 mt-2 px-2">
          <input type="text" v-model="tel" :placeholder="tel" @keypress="onlyNumber"
            class="self-center bg-transparent w-full outline-none px-2 md:text-xl" />
        </div>
      </div>
      <div class="md:flex md:w-3/4 mx-auto mt-2">
        <div class="md:w-1/3 mt-2 text-left md:text-xl">
          Adresse :
        </div>
        <div class="bg-gray-light rounded-xxl md:w-2/3 mt-2 px-2">
          <input type="text" v-model="address" class="self-center bg-transparent w-full outline-none px-2 md:text-xl" />
        </div>
      </div>
      <div class="md:flex md:w-3/4 mx-auto mt-2">
        <div class="md:w-1/3 mt-2 text-left md:text-xl">
          Code Postal :
        </div>
        <div class="bg-gray-light rounded-xxl md:w-2/3 mt-2 px-2">
          <input type="text" v-model="zip" @keypress="onlyNumber"
            class="self-center bg-transparent w-full outline-none px-2 md:text-xl" />
        </div>
      </div>
      <div class="md:flex md:w-3/4 mx-auto mt-2">
        <div class="md:w-1/3 mt-2 text-left md:text-xl">
          Ville :
        </div>
        <div class="bg-gray-light rounded-xxl md:w-2/3 mt-2 px-2">
          <input type="text" v-model="city" class="self-center bg-transparent w-full outline-none px-2 md:text-xl" />
        </div>
      </div>
      <div class="mt-8">
        {{ $t('pages.summary.unsigned_document.modal.paragraph') }}
      </div>
    </Modal>
  </div>
</template>
  
<script>
import { mapState } from "vuex";

export default {
  name: "UnsignedDocument",

  props: {
    document: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      showModal: false,
      firstname: "",
      lastname: "",
      tel: "",
      address: "",
      zip: "",
      city: ""
    };
  },

  computed: {
    ...mapState("user", ["user"]),
  },

  methods: {
    initModal() {
      if (this.document.signing_details && this.document.signing_details.signers && this.document.signing_details.signers.length > 0 && this.document.signing_details.signers[0].signature_link) {
        window.location.replace(this.document.signing_details.signers[0].signature_link);
      } else {
        this.lastname = this.user.last_name;
        this.firstname = this.user.first_name;
        this.tel = this.user.phone;
        this.showModal = true;
      }
    },

    onlyNumber(event) {
      if (!/\d/.test(event.key)) return event.preventDefault();
    },

    async sign() {
      if (this.lastname != '' && this.firstname != '' && this.tel != '' && this.address != '' && this.zip != '' && this.city != '') {
        this.showModal = false;
        this.user.lastname = this.lastname;
        this.user.firstname = this.firstname;
        this.user.phone = this.tel;
        this.$store.dispatch("user/signDocument", {
          document_id: this.document.id,
          last_name: this.lastname.replace(/[^a-zA-ZÀ-ÿ-]/g, ''),
          first_name: this.firstname.replace(/[^a-zA-ZÀ-ÿ-]/g, ''),
          tel: this.tel,
          address: this.address,
          zip: this.zip,
          city: this.city
        });
      }
    }
  }
};
</script>
  