var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row justify-between mb-2 relative margin-percent",attrs:{"id":"gamification-panel"}},[_c('GameAvatar',{staticClass:"z-1",attrs:{"step":0,"label":_vm.labelArray[0],"highlighted":_vm.highlightedArray[0],"purchaseMode":_vm.purchaseMode},on:{"step-change":_vm.stepChange}}),_c('GameAvatar',{staticClass:"z-1",attrs:{"step":1,"label":_vm.labelArray[1],"highlighted":_vm.highlightedArray[1],"purchaseMode":_vm.purchaseMode},on:{"step-change":_vm.stepChange}}),_c('GameAvatar',{staticClass:"z-1",attrs:{"step":2,"label":_vm.labelArray[2],"highlighted":_vm.highlightedArray[2],"purchaseMode":_vm.purchaseMode},on:{"step-change":_vm.stepChange}}),_c('div',{staticClass:"line"}),_c('div',{staticClass:"line highlighted",class:{
      'animated-half': _vm.highlightedArray[1],
      'animated-full': _vm.highlightedArray[2]
    }}),_c('Modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.showModal),expression:"showModal"}],attrs:{"title":_vm.purchaseMode
        ? _vm.$t('pages.summary.gamification.which_pro_purchase')
        : _vm.$t('pages.summary.gamification.which_pro_sale'),"actions":{
      actionVisible: true,
      actionLabel: 'Valider',
      cancelVisible: true,
      cancelLabel: 'Annuler',
      backdropClickEnabled: false,
      actionDisabled:
        _vm.selectedPros.length == 0 && !_vm.noPro && _vm.selectedPro == null
    }},on:{"action":_vm.onModalValidate,"cancel":_vm.onModalCancel}},[_c('div',{staticClass:"pro-select overflow-y-scroll"},[_c('ul',{staticClass:"ml-5 mt-5"},[_vm._l((_vm.proArray),function(pro){return _c('li',{key:pro.id,staticClass:"flex flex-row justify-start items-center cursor-pointer pb-3"},[(_vm.purchaseMode)?_c('RadioButton',{attrs:{"value":pro.id},model:{value:(_vm.selectedPro),callback:function ($$v) {_vm.selectedPro=$$v},expression:"selectedPro"}}):_c('Checkbox',{attrs:{"value":pro.id},on:{"change":_vm.onProCheckChange},model:{value:(_vm.selectedPros),callback:function ($$v) {_vm.selectedPros=$$v},expression:"selectedPros"}}),_c('div',{staticClass:"flex flex-row",on:{"click":function($event){return _vm.onAvatarClick(pro)}}},[_c('Avatar',{attrs:{"name":pro.company,"imageUrl":pro.avatar_url}}),_c('div',{staticClass:"flex-1 ml-3"},[_c('div',{staticClass:" flex flex-col text-left justify-between items-start font-sans"},[_c('div',{staticClass:"text-sm font-bold text-gray-smoke"},[_vm._v(" "+_vm._s(pro.company)+" ")]),_c('div',{staticClass:"text-sm text-gray-smoke break-all"},[_vm._v(" "+_vm._s(pro.email)+" ")])])])],1)],1)}),_c('li',{staticClass:"text-left cursor-pointer"},[(_vm.purchaseMode)?_c('RadioButton',{attrs:{"value":0},model:{value:(_vm.selectedPro),callback:function ($$v) {_vm.selectedPro=$$v},expression:"selectedPro"}}):_c('Checkbox',{on:{"change":_vm.onNoneCheckChange},model:{value:(_vm.noPro),callback:function ($$v) {_vm.noPro=$$v},expression:"noPro"}}),_c('span',{on:{"click":function($event){return _vm.onNoneClick()}}},[_vm._v(_vm._s(_vm.$t("pages.summary.gamification.none_of_them")))])],1)],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }