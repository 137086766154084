<template>
    <Panel
      :title="{
        title: $t('pages.summary.signed_documents.title'),
        size: 'sm'
      }"
    >
      <ul>
        <li
          v-for="document in documents"
          :key="document.id"
          class="border-b border-gray-cloud last:border-b-0 hover:bg-gray-cloud hover:bg-opacity-50"
        >
          <a class="flex flex-row items-center h-12 cursor-pointer"
          :href="document.signing_details.signers[0].signature_link" target="_blank">
            <div class="flex flex-grow flex-row text-left truncate sm:pl-2">
              Mandat de recherche N° {{ document.reference }}
            </div>
          </a>
        </li>
      </ul>
      <Modal
        v-show="showModalConfirm"
        type="success"
        :title="$t('pages.summary.signed_documents.modal_confirm.title')"
        :actions="{
          cancelVisible: false,
          cancelLabel: 'Annuler',
          actionVisible: true,
          actionLabel: $t('pages.summary.signed_documents.modal_confirm.action'),
          backdropClickEnabled: false
        }"
        @action="showModalConfirm = false"
      >
        <div class="md:flex w-3/4 mx-auto mt-6">
          <div class="hidden md:block w-1/4 mt-2">
            <Tick class="w-16 h-16"/>
          </div> 
          <div class="md:w-3/4 text-xl self-center">
            <div>{{ $t('pages.summary.signed_documents.modal_confirm.paragraph_1') }}</div>
          </div>
        </div>
      </Modal>
      <Snackbar v-model="showSnackbar" v-bind="snackbarProps"></Snackbar>
    </Panel>
  </template>
  
  <script>

import Tick from "@/assets/images/tick.svg?inline";
  
  export default {
    name: "SignedDocuments",
  
    props: {
      documents: {
        type: Array
      }
    },

    components: {
      Tick
    },

    data() {
      return {
        showModalConfirm: false,
        showSnackbar: false
      };
    },

    mounted() {
      let urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("signed_mandate")) {
        if (urlParams.get("signed_mandate") == 1 && urlParams.get("signature_request_id")) {
          this.$store.dispatch("user/setSignedDocument", {
          signature_request_id: urlParams.get("signature_request_id")
        });
          this.showModalConfirm = true;
        } 
        if (urlParams.get("signed_mandate") == 0) {
          this.showSnackbar = true;
        }
      }
    },

    computed: {
      snackbarProps() {
        let props = {
          variant: "error",
          text: "Erreur ! La signature de votre mandat de recherche a échouée.",
          action: "OK",
          x: "center",
          y: "top",
          timeout: 5
        };

        return props;
      }
    }
  };
  </script>