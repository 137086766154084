<template>
  <div>
    <Panel
      :title="{
        title: $t('pages.summary.app_install.title'),
        size: 'sm'
      }"
    >
      <div class="flex items-center flex-col lg:flex-row">
        <div class="w-full lg:w-1/2">
          <div class="mb-4">
            {{ $t("pages.summary.app_install.paragraph_1") }}
          </div>
        </div>
        <div class="sm:w-3/4 md:w-1/2 px-4 mb-4 lg:mb-2">
          <a v-if="isAndroid" @click="installClick()">
            <img :src="require('@/assets/images/google-play-badge.png')" />
          </a>
          <a v-else class="w-24" @click="installClick()">
            <ImageAppleStoreBadge class="mx-auto" />
          </a>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ImageAppleStoreBadge from "@/assets/images/apple-store-badge.svg?inline";

export default {
  name: "ProjectAppInstall",

  components: {
    ImageAppleStoreBadge
  },

  props: {
    isAndroid: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapGetters("user", ["gtmProfile"])
  },

  methods: {
    installClick() {
      this.$store.dispatch("user/appInstall");
      this.$gtm.trackEvent({
        event: "user-app-install",
        eventCategory: "External Link",
        eventAction: this.isAndroid ? "Android Play Store" : "Apple App Store",
        eventLabel: this.gtmProfile,
        eventValue: "",
        noninteraction: false
      });
    }
  }
};
</script>
