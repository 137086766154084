<template>
  <div>
    <!-- mobile -->
    <div class="md:hidden">
      <article v-if="purchase">
        <header class="flex justify-between mb-2">
          <h2 class="font-lato font-bold text-primary-40 text-xl text-left">
            {{ $t("pages.summary.gamification.ask") }}
          </h2>
        </header>
        <header v-if="sale" class="font-lato font-bold text-sm  mb-3">
          ACHAT
        </header>
        <Gamification
          :purchaseMode="true"
          :labelArray="[
            $t('pages.summary.gamification.step_nothing'),
            $t('pages.summary.gamification.step_visited'),
            $t('pages.summary.gamification.step_found')
          ]"
        />
      </article>
      <article v-if="sale" class="mt-6">
        <header v-if="sale" class="font-lato font-bold text-sm  mb-3">
          VENTE
        </header>
        <Gamification
          :purchaseMode="false"
          :labelArray="[
            $t('pages.summary.gamification.step_nothing'),
            $t('pages.summary.gamification.step_for_sale'),
            $t('pages.summary.gamification.step_sold')
          ]"
        />
      </article>
    </div>
    <!-- desktop -->
    <div class="hidden md:block">
      <Panel
        :title="{
          title: 'Ou en êtes vous ?',
          size: 'sm'
        }"
      >
        <div class="flex flex-row">
          <img
            class="w-1/3 mr-5"
            v-if="!sale"
            src="@/assets/images/ernest-office.png"
            alt="Offre d'achat"
          />

          <article v-if="purchase" class="flex-1">
            <header v-if="sale" class="font-lato font-bold text-sm  mb-3">
              ACHAT
            </header>
            <Gamification
              ref="purchase"
              :purchaseMode="true"
              :labelArray="[
                $t('pages.summary.gamification.step_nothing'),
                $t('pages.summary.gamification.step_visited'),
                $t('pages.summary.gamification.step_found')
              ]"
            />
          </article>
          <Divider
            v-if="sale"
            :vertical="true"
            variant="thin-light"
            class="w-20 my-auto"
          />
          <article v-if="sale" class="flex-1">
            <header v-if="sale" class="font-lato font-bold text-sm  mb-3">
              VENTE
            </header>
            <Gamification
              ref="sale"
              :purchaseMode="false"
              :labelArray="[
                $t('pages.summary.gamification.step_nothing'),
                $t('pages.summary.gamification.step_for_sale'),
                $t('pages.summary.gamification.step_sold')
              ]"
            />
          </article>
        </div>
      </Panel>
    </div>
    <Snackbar v-model="showSnackbar" v-bind="snackbarProps"></Snackbar>
  </div>
</template>

<script>
import Gamification from "@/components/Gamification/Gamification.vue";
import { mapState } from "vuex";
export default {
  name: "ProjectGamification",
  components: {
    Gamification
  },
  data: function() {
    return {
      showSnackbar: false
    };
  },
  computed: {
    ...mapState("progress", ["purchase", "sale"]),

    snackbarProps() {
      return {
        variant: "success",
        text: "Merci pour votre réponse !",
        action: "OK",
        x: "center",
        y: "top",
        timeout: 5
      };
    }
  },
  mounted() {
    let urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get("mail_reply")) {
      setTimeout(() => {
        switch (urlParams.get("mail_reply")) {
          case "purchase_progress_1":
            if (this.purchase && this.purchase.step == 0) {
              this.$refs.purchase.stepChange(1);
              this.showSnackbar = true;
            }
            break;
          case "purchase_progress_2":
            if (this.purchase && this.purchase.step != 2) {
              this.$refs.purchase.chosenStep = 2;
              this.$refs.purchase.showModal = true;
            }
            break;
          case "sale_progress_1":
            if (this.sale && this.sale.step != 1) {
              this.$refs.sale.chosenStep = 1;
              this.$refs.sale.showModal = true;
            }
            break;
          case "sale_progress_2":
            if (this.sale && this.sale.step != 2) {
              this.$refs.sale.chosenStep = 2;
              this.$refs.sale.showModal = true;
            }
            break;
          case "thanks":
            this.showSnackbar = true;
            break;
          default:
            break;
        }
      }, 1000);
    }
  },
  created() {
    this.$root.$on("openProgressPurchaseStepFound", () => {
      this.$refs.purchase.chosenStep = 2;
      this.$refs.purchase.showModal = true;
    });
    this.$root.$on("openProgressSaleStepMandat", () => {
      this.$refs.sale.chosenStep = 1;
      this.$refs.sale.showModal = true;
    });
    this.$root.$on("openProgressSaleStepCompromis", () => {
      this.$refs.sale.chosenStep = 2;
      this.$refs.sale.showModal = true;
    });
  }
};
</script>

<style></style>
