export default {
  isAndroid() {
    return /Android/i.test(navigator.userAgent);
  },
  isIOS() {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  },
  isMobileApp() {
    return /AppChercheMonNid/i.test(navigator.userAgent);
  }
};
