<template>
  <Panel
    :title="{
      title: $t('pages.summary.insurance.title'),
      size: 'sm'
    }"
  >
    <div class="flex items-center flex-col md:flex-row">
      <div class="w-full md:w-1/2">
        <div class="mt-2" style="font-size: x-large; font-weight: 600;">
          {{ $t("pages.summary.insurance.paragraph_1") }}
        </div>
        <div class="mt-2 text-xl">
          {{ $t("pages.summary.insurance.paragraph_2") }}
        </div>
        <Button class="my-8" style="background-color: #E2136E; border-color: #E2136E;" shape="pills" @click="InsuranceClick()">{{$t("pages.summary.insurance.action")}}</Button>
      </div>
      <img class="mb-2 w-full md:w-1/2" :src="require('@/assets/images/assurance-habitation.png')"/>
    </div>
    <div class="flex items-center justify-center md:gap-8 mt-4 flex-col md:flex-row">
      <img class="mb-2 w-2/3 md:w-1/3" :src="require('@/assets/images/cardif-logo.png')"/>
      <img class="mb-2 w-1/2 md:w-1/5" :src="require('@/assets/images/lemonade-logo.png')"/>
    </div>
  </Panel>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProjectBlog",

  computed: {
    ...mapGetters("user", ["gtmProfile"])
  },

  methods: {
    InsuranceClick() {
      this.$gtm.trackEvent({
        event: "user-insurance",
        eventCategory: "External Link",
        eventAction: "Insurance",
        eventLabel: this.gtmProfile,
        eventValue: "",
        noninteraction: false
      });
      window.open("https://www.cardif.fr/assurance-habitation/cherchemonnid?at_medium=partners&at_campaign=mrh_cherche_mon_nid&at_custom_var6=cherche_mon_nid&at_custom_var28=email&at_custom_var29=lp_cardif_cherche_mon_nid&utm_source=cardif_mrh_fr&utm_medium=partners_online&utm_content=email&utm_campaign=partners_cherche_mon_nid", "_blank");
    }
  }
};
</script>

<style class="postcss" scoped></style>
