var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"md:hidden"},[(_vm.hasSearches)?_c('article',{staticClass:"mb-10"},[_c('header',{staticClass:"flex justify-between mb-2"},[_c('h2',{staticClass:"font-lato font-bold text-primary-40 text-xl"},[_vm._v(" "+_vm._s(_vm.$t("pages.summary.your_latest_interactions_mobile"))+" ")])]),(_vm.interactionsForList.length > 0)?_c('div',{staticClass:"flex flex-col items-center p-6 bg-gray-cloud bg-opacity-50 rounded-xl"},[_c('ProjectInteractionList',{attrs:{"interactions":_vm.interactionsForList,"professionals":_vm.professionals}}),_c('div',{staticClass:"mt-4"},[_c('router-link',{attrs:{"to":{
              name: 'ProjectChats'
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
return [_c('Button',{attrs:{"appearance":"outlined","shape":"pills","href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t("pages.summary.interaction_open_details")))])]}}],null,false,3977324930)})],1)],1):_vm._e(),(_vm.interactionsForList.length == 0)?_c('div',{staticClass:"flex flex-col items-center p-6 bg-gray-cloud bg-opacity-50 rounded-xl"},[_c('div',{staticClass:"text-sm"},[_c('div',{staticClass:"mb-2 font-bold"},[_vm._v(" "+_vm._s(_vm.$t("pages.summary.no_interactions"))+" ")])]),_c('div',{staticClass:"p-3"},[_c('ImageSearchSent')],1)]):_vm._e()]):_vm._e(),_c('article',[_c('header',{staticClass:"flex justify-between mb-2"},[_c('h2',{staticClass:"font-lato font-bold text-primary-40 text-xl"},[_vm._v(" "+_vm._s(_vm.$t("pages.summary.your_statistics"))+" ")])]),_c('div',{staticClass:"flex justify-around items-start p-6 bg-gray-cloud bg-opacity-50 rounded-xl"},[_c('ProjectBarChart',{attrs:{"type":"mail","value":_vm.interactionCount.email,"total":_vm.totalInteractions}}),_c('ProjectBarChart',{attrs:{"value":_vm.interactionCount.phone,"total":_vm.totalInteractions}}),_c('ProjectBarChart',{attrs:{"type":"proposal","value":_vm.interactionCount.chat,"total":_vm.totalInteractions}})],1)])]),_c('div',{staticClass:"hidden md:block"},[_c('Panel',{attrs:{"title":{
        title: _vm.$t('pages.summary.project_related_interactions'),
        size: 'sm'
      }}},[_c('div',{staticClass:"flex flex-row items-center"},[_c('div',{staticClass:"flex-1 flex-grow-1"},[_c('div',{staticClass:"flex flex-row justify-around items-center mr-4"},[_c('ProjectCircleChart',{attrs:{"type":"mail","value":_vm.interactionCount.email,"total":_vm.totalInteractions}}),_c('ProjectCircleChart',{attrs:{"value":_vm.interactionCount.phone,"total":_vm.totalInteractions}}),_c('ProjectCircleChart',{attrs:{"type":"proposal","value":_vm.interactionCount.chat,"total":_vm.totalInteractions}})],1),(_vm.interactionsForList.length > 0)?_c('div',{staticClass:"mt-4"},[_c('router-link',{attrs:{"to":{
                name: 'ProjectInteractions',
                params: { chatId: _vm.interactionsForList[0].chat_id }
              }}},[_c('Button',{attrs:{"size":"sm","shape":"pills"}},[_vm._v(_vm._s(_vm.$t("pages.summary.interaction_open_details")))])],1)],1):_vm._e()]),(!_vm.hasSearches)?_c('div',{staticClass:"flex items-center flex-col"},[_c('div',{staticClass:"text-sm"},[_c('div',{staticClass:"mb-2 font-bold"},[_vm._v(" "+_vm._s(_vm.$t("pages.summary.no_searches"))+" ")])]),_c('div',{staticClass:"p-3"},[_c('ImageSearchSent')],1)]):_vm._e(),(_vm.hasSearches && _vm.interactionsForList.length == 0)?_c('div',{staticClass:"flex items-center flex-col"},[_c('div',{staticClass:"text-sm"},[_c('div',{staticClass:"mb-2 font-bold"},[_vm._v(" "+_vm._s(_vm.$t("pages.summary.no_interactions"))+" ")])]),_c('div',{staticClass:"p-3"},[_c('ImageSearchSent')],1)]):_vm._e(),(_vm.hasSearches && _vm.interactionsForList.length > 0)?_c('div',{staticClass:"flex-1 flex-grow-1 interactions-last"},[_c('ProjectInteractionList',{attrs:{"title":_vm.$t('pages.summary.your_latest_interactions'),"interactions":_vm.interactionsForList,"professionals":_vm.professionals}})],1):_vm._e()])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }