import { theme } from "../../../tailwind.config.js";

// TODO: try to get from InteractionBadge design system component
export const INTERACTION_TYPES = {
  PHONE: "phone",
  MAIL: "mail",
  PROPOSAL: "proposal"
};

const gradientNames = {
  [INTERACTION_TYPES.PHONE]: "sunset",
  [INTERACTION_TYPES.MAIL]: "sky",
  [INTERACTION_TYPES.PROPOSAL]: "raspberry"
};

const legends = {
  [INTERACTION_TYPES.PHONE]: "Téléphone affiché",
  [INTERACTION_TYPES.MAIL]: "Mail affiché",
  [INTERACTION_TYPES.PROPOSAL]: "Messages"
};

export default {
  name: "ProjectChart",

  props: {
    type: {
      type: String,
      default: "phone",
      validator: value => Object.values(INTERACTION_TYPES).indexOf(value) !== -1
    },

    value: {
      type: Number,
      default: 0,
      required: true
    },

    total: {
      type: Number,
      default: 100,
      required: true
    }
  },

  computed: {
    gradientColors() {
      return theme.gradientColorStops[gradientNames[this.type]];
    },

    legend() {
      return legends[this.type];
    },

    percent() {
      if (isNaN(this.total) || this.total === 0) {
        return 0;
      }

      return this.value / this.total;
    }
  }
};
