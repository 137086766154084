var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"md:hidden"},[(_vm.purchase)?_c('article',[_c('header',{staticClass:"flex justify-between mb-2"},[_c('h2',{staticClass:"font-lato font-bold text-primary-40 text-xl text-left"},[_vm._v(" "+_vm._s(_vm.$t("pages.summary.gamification.ask"))+" ")])]),(_vm.sale)?_c('header',{staticClass:"font-lato font-bold text-sm  mb-3"},[_vm._v(" ACHAT ")]):_vm._e(),_c('Gamification',{attrs:{"purchaseMode":true,"labelArray":[
          _vm.$t('pages.summary.gamification.step_nothing'),
          _vm.$t('pages.summary.gamification.step_visited'),
          _vm.$t('pages.summary.gamification.step_found')
        ]}})],1):_vm._e(),(_vm.sale)?_c('article',{staticClass:"mt-6"},[(_vm.sale)?_c('header',{staticClass:"font-lato font-bold text-sm  mb-3"},[_vm._v(" VENTE ")]):_vm._e(),_c('Gamification',{attrs:{"purchaseMode":false,"labelArray":[
          _vm.$t('pages.summary.gamification.step_nothing'),
          _vm.$t('pages.summary.gamification.step_for_sale'),
          _vm.$t('pages.summary.gamification.step_sold')
        ]}})],1):_vm._e()]),_c('div',{staticClass:"hidden md:block"},[_c('Panel',{attrs:{"title":{
        title: 'Ou en êtes vous ?',
        size: 'sm'
      }}},[_c('div',{staticClass:"flex flex-row"},[(!_vm.sale)?_c('img',{staticClass:"w-1/3 mr-5",attrs:{"src":require("@/assets/images/ernest-office.png"),"alt":"Offre d'achat"}}):_vm._e(),(_vm.purchase)?_c('article',{staticClass:"flex-1"},[(_vm.sale)?_c('header',{staticClass:"font-lato font-bold text-sm  mb-3"},[_vm._v(" ACHAT ")]):_vm._e(),_c('Gamification',{ref:"purchase",attrs:{"purchaseMode":true,"labelArray":[
              _vm.$t('pages.summary.gamification.step_nothing'),
              _vm.$t('pages.summary.gamification.step_visited'),
              _vm.$t('pages.summary.gamification.step_found')
            ]}})],1):_vm._e(),(_vm.sale)?_c('Divider',{staticClass:"w-20 my-auto",attrs:{"vertical":true,"variant":"thin-light"}}):_vm._e(),(_vm.sale)?_c('article',{staticClass:"flex-1"},[(_vm.sale)?_c('header',{staticClass:"font-lato font-bold text-sm  mb-3"},[_vm._v(" VENTE ")]):_vm._e(),_c('Gamification',{ref:"sale",attrs:{"purchaseMode":false,"labelArray":[
              _vm.$t('pages.summary.gamification.step_nothing'),
              _vm.$t('pages.summary.gamification.step_for_sale'),
              _vm.$t('pages.summary.gamification.step_sold')
            ]}})],1):_vm._e()],1)])],1),_c('Snackbar',_vm._b({model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},'Snackbar',_vm.snackbarProps,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }