<template>
  <figure class="inline-block" :style="`width: ${width}px`">
    <router-link :to="{ name: 'ProjectChats' }">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :viewBox="`0 0 ${width} ${height}`"
      >
        <defs>
          <linearGradient
            :id="`chartGradient-bar-${this.type}`"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop offset="0%" :stop-color="gradientColors.stop" />
            <stop offset="100%" :stop-color="gradientColors.start" />
          </linearGradient>
        </defs>
        <g>
          <rect
            x="0"
            y="0"
            :width="width"
            :height="height"
            rx="5"
            ry="5"
            fill="currentColor"
            class="text-gray-cloud"
          />
          <rect
            x="0"
            :y="barHeight"
            :width="width"
            :height="height - barHeight"
            rx="5"
            ry="5"
            :fill="`url(#chartGradient-bar-${this.type})`"
          />
          <text
            :x="width / 2"
            :y="25"
            text-anchor="middle"
            alignment-baseline="middle"
            :fill="gradientColors.start"
            class="font-lato font-black text-xl"
          >
            {{ value }}
          </text>
        </g>
      </svg>
      <figcaption class="mt-3 font-sans text-xs text-gray-smoke text-center">
        {{ legend }}
      </figcaption>
    </router-link>
  </figure>
</template>

<script>
import ProjectChart from "@/components/project/ProjectChartMixin";

export default {
  name: "ProjectBarChart",

  mixins: [ProjectChart],

  data() {
    return {
      width: 70,
      height: 115
    };
  },

  computed: {
    barHeight() {
      return this.height - this.height * this.percent;
    }
  }
};
</script>
