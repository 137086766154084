<template>
  <Panel
    :title="{
      title: $t('pages.summary.blog.title'),
      size: 'sm'
    }"
  >
    <ul>
      <li
        class="border-b border-gray-cloud last:border-b-0 hover:bg-gray-cloud hover:bg-opacity-50"
      >
        <a
          :href="$t('pages.summary.blog.article_1.link')"
          target="blank"
          @click="trackBlogClick()"
        >
          <div class="flex flex-col lg:flex-row items-center">
            <div class="flex-none">
              <img
                class="h-20 m-4"
                :src="require('@/assets/images/blog-article-1.png')"
              />
            </div>
            <div class="flex-grow mx-2">
              <div class="font-bold text-left">
                {{ $t("pages.summary.blog.article_1.title") }}
              </div>
              <div class="text-left">
                {{ $t("pages.summary.blog.article_1.excerpt") }}
              </div>
            </div>

            <div class="flex-none my-2">
              <a class="pr-2 text-primary-40">{{
                $t("pages.summary.blog.read_more")
              }}</a>
            </div>
          </div>
        </a>
      </li>
      <li
        class="border-b border-gray-cloud last:border-b-0 hover:bg-gray-cloud hover:bg-opacity-50"
      >
        <a
          :href="$t('pages.summary.blog.article_2.link')"
          target="blank"
          @click="trackBlogClick()"
        >
          <div class="flex flex-col lg:flex-row items-center">
            <div class="flex-none">
              <img
                class="h-20 m-4"
                :src="require('@/assets/images/blog-article-2.png')"
              />
            </div>
            <div class="flex-grow mx-2">
              <div class="font-bold text-left">
                {{ $t("pages.summary.blog.article_2.title") }}
              </div>
              <div class="text-left">
                {{ $t("pages.summary.blog.article_2.excerpt") }}
              </div>
            </div>

            <div class="flex-none my-2">
              <a class="pr-2 text-primary-40">{{
                $t("pages.summary.blog.read_more")
              }}</a>
            </div>
          </div>
        </a>
      </li>
      <li
        class="border-b border-gray-cloud last:border-b-0 hover:bg-gray-cloud hover:bg-opacity-50"
      >
        <a
          :href="$t('pages.summary.blog.article_3.link')"
          target="blank"
          @click="trackBlogClick()"
        >
          <div class="flex flex-col lg:flex-row items-center">
            <div class="flex-none">
              <img
                class="h-20 m-4"
                :src="require('@/assets/images/blog-article-3.png')"
              />
            </div>
            <div class="flex-grow mx-2">
              <div class="font-bold text-left">
                {{ $t("pages.summary.blog.article_3.title") }}
              </div>
              <div class="text-left">
                {{ $t("pages.summary.blog.article_3.excerpt") }}
              </div>
            </div>

            <div class="flex-none my-2">
              <a class="pr-2 text-primary-40">{{
                $t("pages.summary.blog.read_more")
              }}</a>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </Panel>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProjectBlog",

  computed: {
    ...mapGetters("user", ["gtmProfile"])
  },

  methods: {
    trackBlogClick() {
      this.$gtm.trackEvent({
        event: "user-blog",
        eventCategory: "External Link",
        eventAction: "Blog",
        eventLabel: this.gtmProfile,
        eventValue: "",
        noninteraction: false
      });
    }
  }
};
</script>

<style class="postcss" scoped></style>
