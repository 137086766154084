<template>
  <Panel
    :title="{
      title: $t('pages.summary.your_searches'),
      size: 'sm'
    }"
  >
    <SearchList :searches="searches" />
  </Panel>
</template>

<script>
import SearchList from "@/components/search/SearchList.vue";

export default {
  name: "ProjectSearches",

  components: {
    SearchList
  },

  props: {
    searches: {
      type: Array
    }
  }
};
</script>

<style class="postcss" scoped></style>
