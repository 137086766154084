const cmnDesignSystem = require("@ONELABCMN/design-system/tailwind.config");

module.exports = {
  future: {
    // removeDeprecatedGapUtilities: true,
    // purgeLayersByDefault: true,
  },
  purge: [],
  theme: {
    ...cmnDesignSystem.theme,
    extend: {
      ...cmnDesignSystem.theme.extend,
      colors: {
        ...cmnDesignSystem.theme.extend.colors,
        sunset: {
          default: "#F0B083",
          200: "#E79D6C",
          300: "#E08A55",
          400: "#D9763E",
          500: "#D16327",
          600: "#B8541F",
        }
      },
      boxShadow: {
        ...cmnDesignSystem.theme.extend.boxShadow,
        header: "0px 2px 2px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1)"
      },
      fontSize: {
        ...cmnDesignSystem.theme.extend.fontSize,
        md: "1.125rem"
      },
      minHeight: {
        ...cmnDesignSystem.theme.extend.minHeight,
        "16": "4rem"
      },
      maxHeight: {
        ...cmnDesignSystem.theme.extend.maxHeight,
        "1/4": "25%",
        "1/2": "50%"
      },
      rotate: {
        ...cmnDesignSystem.theme.extend.rotate,
        "-30": "-30deg"
      },
      spacing: {
        ...cmnDesignSystem.theme.extend.spacing,
        "1/2": "50%",
        "1/3": "30%",
        "1/4": "25%",
        "1/20": "5%",
        "1/25": "4%"
      },
      zIndex: {
        ...cmnDesignSystem.theme.extend.zIndex,
        "-1": "-1",
        "-2": "-2",
        "-3": "-3"
      },
      backgroundImage: {
        'no-image': 'url("../../assets/images/no-image.svg")'
      },
      borderWidth: {
        ...cmnDesignSystem.theme.extend.borderWidth,
        "1/2": "0.5px"
      },
    }
  },
  variants: {
    ...cmnDesignSystem.variants,
    backgroundColor: ({ after }) => after(["group-hover"]),
    backgroundOpacity: ({ after }) => after(["group-hover"]),
    borderColor: ({ after }) => after(["focus-within"]),
    gridColumn: ({ after }) => after(["last"]),
    margin: ({ after }) => after(["last", "even"]),
    textColor: ({ after }) => after(["group-hover"]),
    borderWidth: ["responsive", "hover", "last"]
  },
  plugins: []
};
